@import 'styles/variables';

.switch {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 60px;
  min-height: 27px !important;
  height: 27px;
  padding: 0 14px;
  border-radius: 14px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;

  &:hover {
    cursor: pointer;
  }

  .clickableArea {
    padding: 8px 5px;

    .circle {
      display: flex;
      width: 11px;
      height: 11px;
      background-color: $white;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      z-index: 2;

      &:last-child {
        margin-right: 0;
      }

      &.selected {
        opacity: 1;
      }
    }
  }
}
