@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.checkout {
  background-color: $bg-grey;
  height: 100%;

  h3 {
    padding: 15px 0;
    text-align: center;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    opacity: 0.9;
  }

  .card {
    margin: 20px 10px;
    background-color: $white;
    border-radius: 4px;
    overflow-x: hidden;

    h4 {
      letter-spacing: 3.5px;
      color: $main;
      opacity: 0.8;
      font-family: $avenir-black;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);

      .icon {
        margin: 0;
        display: flex;

        &.card {
          margin-right: -16px;
        }
      }
    }

    .form {
      padding: 15px;

      .input {
        position: relative;

        .calendar {
          path {
            fill: $main;
          }
        }

        svg {
          position: absolute;
          top: 11px;
          left: 14px;
        }
      }

      .bottom {
        height: 120px;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);
        background-color: #fff;

        @include small-desktop {
          width: 50%;
          left: unset;
        }

        @include desktop {
          width: 35%;
          left: unset;
        }

        button {
          width: 70%;
        }
      }
    }

    .hidden {
      visibility: hidden;
    }
  }
}
