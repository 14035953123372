@import 'styles/variables';

.switchContainer {
  position: relative;
  height: 46px;
  border-radius: 23px;
  backdrop-filter: blur(7px);
  background-color: $light-grey;
  transition: background-color 0.2s;

  .container {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;

    &.button {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }

    &.selectedFirst {
      transform: translate(100%, -50%);
    }

    &.selectedSecond {
      transform: translate(100%, -50%);
    }
  }

  .labels {
    display: relative;
    z-index: 0;
    height: 100%;
    font-size: 14px;
    font-family: $avenir-heavy;
    cursor: pointer;

    span {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48%;
      height: 100%;
      padding: 0 7px;
      transition: color 0.2s;

      &:first-child {
        left: 10px;
      }
      &:last-child {
        right: 10px;
      }
    }
  }
}

.button {
  position: absolute;
  width: 48%;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s;
  background: $main;
  border-radius: 23px;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.nothingSelected {
  background: transparent;
  height: auto;
}
