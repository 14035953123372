@import 'styles/variables.scss';

.dialog {
  .appBar {
    position: relative;

    .remove {
      font-family: $avenir-medium;
      position: absolute;
      right: 15px;
      color: $error;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .title {
    font-size: 18px;
    letter-spacing: -0.1px;
    width: 70%;
    text-align: center;
  }

  .modalContent {
    padding: 25px 15px;

    .label {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.25;
      opacity: 0.9;
      font-family: $avenir-heavy;
    }

    .priceLabel {
      margin-top: 25px;
    }
  }

  .buttons {
    margin: auto auto 0;
    padding-bottom: 40px;

    button {
      width: 135px;

      &:first-child {
        margin-right: 10px;
        color: #888;
      }
    }
  }
}
