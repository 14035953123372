@import 'styles/variables';
@import 'styles/mixins';

.notification {
  min-height: 64px;
  margin: 15px 0;
  padding: 8px;
  border-radius: 10px;
  background-color: #ebedf2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    margin-top: 0;
  }

  &.hover {
    transition: transform 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(0.98);
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 15px;

    h3 {
      font-size: 15px;
      font-weight: 900;
    }

    p {
      font-weight: 400;

      .viewMore {
        color: $main;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  .close {
    margin-left: auto;
    display: flex;
    align-self: flex-start;
  }
}

.red {
  background-color: #fce7e7;
}

.icon {
  min-width: 40px;
  width: 40px;
  height: 40px;
  background-color: #5b00db;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    filter: brightness(0) invert(1);
    width: 25px;
    height: 25px;
  }
}

.redIcon {
  background-color: #ff4b4b;
}

.container {
  min-height: 100%;
  padding: 70px 15px 120px;

  @media screen and (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
  }

  @include small-desktop-and-up {
    width: 55%;
    padding-bottom: 10px;
  }

  @include desktop {
    width: 40%;
  }

  @media screen and (min-width: 1700px) {
    width: 30%;
  }
}
