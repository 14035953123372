@import 'styles/variables';

.cart {
  height: 100%;
  background-color: #f8f9fb;
  text-align: center;
  overflow-x: hidden;
  padding-bottom: 140px;

  h3 {
    color: $main;
    letter-spacing: 3.5px;
    font-size: 14px;
    opacity: 0.8;
    font-family: $avenir-black;
  }

  .header {
    position: relative;
    font-size: 16px;
    letter-spacing: -0.09px;
    font-family: $avenir-heavy;
    padding: 15px;
    border-bottom: 1px solid $border-grey;

    button {
      padding: 20px;
      position: absolute;
      top: 0;
      left: -5px;
    }
  }

  .content {
    padding: 20px 15px 0;

    .info {
      text-align: left;
      margin-bottom: 20px;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }

      .notLoggedInBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -20px -15px 20px;
        background-color: $light-grey;
        padding: 20px 15px;

        button {
          height: 30px;
          min-width: 80px;
          width: 80px;
        }

        h4 {
          font-size: 16px;
          line-height: 25px;
          opacity: 0.9;
        }
      }
    }

    .profile {
      border-radius: 10px;
      background-color: $white;
      margin-bottom: 20px;

      .profileHeader {
        display: flex;
        align-items: center;
        padding: 10px 15px;

        &:hover {
          cursor: pointer;
        }

        span {
          font-size: 16px;
          line-height: 1.25;
          opacity: 0.9;
          margin-left: 10px;
          font-family: $avenir-heavy;
        }
      }

      .items {
        width: 100%;

        .cartItem {
          display: flex;
          flex-wrap: wrap;
          padding: 15px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);

          &:last-child {
            border-bottom: 0;
          }

          .network {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;

            .space {
              font-size: 18px;
              font-family: $avenir-heavy;
              letter-spacing: -0.1px;
              margin-left: 10px;
            }

            .price {
              font-size: 18px;
              line-height: 1.67;
              letter-spacing: 0.41px;
              font-family: $avenir-heavy;
              color: $main;
            }

            span:last-child {
              margin-left: auto;
            }
          }

          .bottom {
            width: 100%;
            display: flex;
            font-size: 13px;

            span {
              padding: 0 5px;
              border-right: 1px solid $primary-dark;
              height: 16px;

              &:last-of-type {
                border-right: 0;
              }

              &:first-child {
                padding-left: 0;
              }
            }

            .actions {
              margin-left: auto;

              svg:first-child {
                margin-right: 25px;
              }

              .hover:hover {
                cursor: pointer;
                filter: brightness(0.8);
              }
            }
          }
        }

        .discountCode {
          display: flex;
          align-items: center;
          padding: 15px;

          &:hover {
            cursor: pointer;
          }

          svg {
            margin-right: 10px;
          }
        }
      }
    }

    .offer {
      display: flex;
      align-items: center;
      padding: 10px 0 30px;

      button {
        background-color: #dffced;
        border-radius: 17px;
        color: $main;
        height: auto;
      }

      p {
        text-align: left;
        margin-left: 10px;
        font-size: 16px;
        font-family: $avenir-medium;
        letter-spacing: -0.09px;
      }
    }
  }

  .subtotal,
  .payment {
    text-align: left;
    text-align: left;
    font-size: 16px;
    letter-spacing: -0.09px;
    font-family: $avenir-medium;
    background-color: $white;
    margin-bottom: 20px;

    h3 {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: 15px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: 15px;

      &:last-child {
        border-bottom: 0;
      }

      div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .bundle {
        justify-content: flex-end;

        span:nth-child(2) {
          width: 80px;
          text-align: right;
        }
      }

      .price {
        font-size: 20px;
        letter-spacing: -0.11px;
        font-family: $avenir-heavy;
        color: $main;
      }
    }
  }

  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    &:first-child {
      margin-right: -13px;
    }

    &:last-child {
      border-bottom: 0;
      margin-right: -15px;
      display: none;
    }

    &:nth-last-child(2) {
      display: none;
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);
  background-color: $white;

  .price {
    min-width: 100px;
    font-size: 26px;
    font-family: $avenir-black;
    letter-spacing: 0.6px;
    margin-right: 30px;
    text-align: center;
  }
}

.wishInfo {
  .wishImage {
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid $light-grey;

    img {
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .wishContainer {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);
  }

  .form {
    text-align: left;
    margin-top: 30px;
    padding-bottom: 10px;
  }

  .formPreview {
    margin-top: 30px;

    .row {
      margin-bottom: 20px;
      text-align: left;

      .contribution {
        color: $main;
        font-weight: bold;
      }
    }
  }

  .button {
    height: 40px;
    width: 150px;
  }
}
