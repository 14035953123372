@import 'styles/mixins';
@import 'styles/variables.scss';

.wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 20px;
  padding-bottom: 40px;
  background-color: $white;
  z-index: 2;
  box-shadow: 0 2px 30px 0 rgb(91 0 219 / 10%);

  @include small-desktop-and-up {
    left: 220px;
  }

  .container {
    margin: 0 auto;
    width: 85%;

    @include tablet {
      width: 60%;
    }

    @include small-desktop {
      width: 40%;
    }

    @include desktop {
      width: 490px;
    }

    .stepper {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .step {
        height: 4px;
        width: 58px;
        border-radius: 5px;
        background-color: $light-grey;

        &:last-child {
          margin-right: 0;
        }

        &.width {
          width: 42px;
        }
      }

      .active {
        background-color: $main;
      }

      .hover {
        cursor: pointer;
      }
    }
  }
}
