@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.maxHeight {
  min-height: 100%;
  padding-top: 90px;
  padding-bottom: 144px;
  overflow: auto;
}

.padding {
  padding: 0;
}

.selectedNetworks {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;

  .network {
    width: 25%;
    padding: 10px 20px;
    margin-bottom: 30px;

    &:hover {
      cursor: pointer;
    }

    &.selectedNetwork {
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      background: $bg-grey;

      span {
        opacity: 1;
      }
    }

    &.allBorder {
      border-radius: 15px;
    }

    &.noMargin {
      margin-bottom: 0;
    }
  }

  .hoverImg:hover {
    cursor: pointer;
  }
}

.description {
  display: flex;
  // height: 245px;
  justify-content: center;
  text-align: center;
  align-items: center;
  opacity: 0.5;
  padding: 0 40px 128px;

  @media screen and (min-width: 600px) {
    font-size: 16px;
  }
}

.wrapper {
  @include small-desktop-and-up {
    left: 0;
  }
}
