@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.sidebar {
  width: 220px;
  background-color: $dark-grey;

  a {
    color: $white;
  }

  ul {
    position: sticky;
    list-style-type: none;

    .accordion:hover {
      h3 {
        color: $main-green;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }

    .heading {
      display: flex;
      align-items: center;
      height: 45px;
      padding: 0 20px;

      &:hover {
        h3 {
          color: $main-green;
          transition: all 0.2s ease-in-out;
        }

        svg {
          filter: invert(60%) sepia(17%) saturate(1200%) hue-rotate(120deg) brightness(120%)
            contrast(100%);
          transition: all 0.2s ease-in-out;
        }

        .userIcon {
          filter: invert(82%) sepia(17%) saturate(1218%) hue-rotate(112deg) brightness(135%)
            contrast(100%) !important;
          transition: all 0.2s ease-in-out;
        }

        .greyColor {
          filter: invert(60%) sepia(17%) saturate(1200%) hue-rotate(120deg) brightness(170%)
            contrast(100%);
        }
      }

      h3 {
        color: $light-grey;
        font-size: 16px;
        margin-left: 15px;
        letter-spacing: -0.09px;
      }

      svg {
        width: 21px;
        min-height: 20px;
        height: 20px;
      }
    }

    .chevronIcon {
      filter: invert(32%) sepia(1%) saturate(100%) hue-rotate(177deg) brightness(98%) contrast(94%);
    }

    .menuList {
      background-color: #000;
      margin-bottom: 10px;

      li {
        color: $light-grey;
        font-family: $avenir-heavy;
        font-size: 16px;
        letter-spacing: -0.09;
        padding-left: 30px;
      }
    }

    .active {
      li {
        color: $main-green;
      }

      h3 {
        color: $main-green;
      }

      svg {
        filter: invert(60%) sepia(17%) saturate(1200%) hue-rotate(120deg) brightness(120%)
          contrast(100%);
      }

      .userIcon {
        filter: invert(51%) sepia(17%) saturate(1218%) hue-rotate(112deg) brightness(120%)
          contrast(102%) !important;
      }

      .greyColor {
        filter: invert(60%) sepia(17%) saturate(1200%) hue-rotate(120deg) brightness(170%)
          contrast(100%);
      }
    }

    .userIcon {
      filter: invert(50%) sepia(12%) saturate(294%) hue-rotate(205deg) brightness(110%)
        contrast(100%);
    }

    .greyColor {
      filter: invert(62%) sepia(8%) saturate(397%) hue-rotate(205deg) brightness(155%) contrast(85%);
    }
  }

  .top {
    top: 100px;
  }

  .masqueradingTop {
    top: 120px;
  }

  .switchButton {
    display: flex;
    height: 35px;
    border-radius: 20px;
    justify-content: center;
    background-color: rgba(18, 223, 199, 0.1);
    margin: 0 auto;
    padding: 0 15px 0 10px;
    margin: 0 20px 30px;

    &:hover {
      cursor: pointer;
    }

    > div {
      width: 100%;
      display: flex;
      align-items: center;

      h3 {
        font-size: 16px;
        color: $light-grey;
        letter-spacing: -0.09px;
        margin: 0 5px 0 10px;
      }

      svg {
        margin-left: auto;
      }
    }
  }
}
