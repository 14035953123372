@import 'styles/variables';

.dropzone {
  position: relative;
  width: 100%;
  min-height: 96px;
  height: auto;
  border-radius: 10px;
  border: 1px dashed $mid-grey;
  background-color: rgba(235, 237, 242, 0.15);
  padding: 15px;
  outline: none;

  &.pending {
    background-color: $light-grey;
    border: none;
  }

  &.verified {
    background-color: #e8fefc;
    border: none;
  }

  &.rejected {
    border: 2px solid $error;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: #9f9ead;
    min-height: 66px;

    button {
      margin-left: 10px;
      height: 100%;
    }

    .contentInfo {
      width: 100%;
      display: flex;

      .checkIcon {
        position: absolute;
        top: 25px;
        left: 5px;
      }

      img {
        min-width: 90px;
        border-radius: 8px;
        object-fit: cover;
        margin-right: 10px;
      }

      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 5px;
        text-align: left;

        .title {
          width: 100%;
          font-family: $avenir-heavy;
          font-size: 15px;
          color: $primary-dark;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        }
      }
    }
  }
}

.thumb {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.binImg {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.img {
  width: 86px;
  height: 86px;
  border-radius: 8px;
}

.progress {
  width: 100%;
  bottom: -10px;
}

.error {
  margin-top: 10px;
  color: $error;
  display: block;
}
