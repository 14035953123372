@import 'styles/variables';
@import 'styles/mixins';

.container {
  min-height: 100%;
  text-align: center;
  position: relative;
  padding-top: 50px;
  overflow-x: hidden;

  @media screen and (min-width: 500px) {
    width: 70%;
    margin: 0 auto;
  }

  @include small-desktop {
    width: 50%;
  }

  @include desktop {
    width: 40%;
  }

  h1 {
    font-size: 24px;
    margin: 20px 20px 40px;

    @include desktop {
      font-size: 36px;
    }
  }

  h3 {
    color: $main;
    letter-spacing: 3.5px;
    font-size: 14px;
    font-family: $avenir-black;
    opacity: 0.8;
  }
}

.closeIcon {
  background-color: $white;

  @include small-desktop-and-up {
    left: 150px;
  }

  @media screen and (min-width: 1500px) {
    left: calc((100% - 1140px) / 2);
  }
}
