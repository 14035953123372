@import 'styles/variables';
@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .gallery {
    position: relative;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-dark;
    background-image: linear-gradient(to right, #5b00db 13%, #8855cf 88%);
    cursor: pointer;

    &.whiteBackground {
      background-color: $white;
    }

    button {
      background: rgba(0, 0, 0, 0.7);
    }
  }

  .shadow {
    position: absolute;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(to right, #5b00db 13%, #8855cf 88%);
  }

  .form {
    width: 100%;
    height: calc(100% - 66px);
    padding: 0 20px 155px;

    @include tablet {
      width: 70%;
      margin: 0 auto;
    }

    h3 {
      font-size: 24px;
      margin: 20px 0 20px;
    }

    h5 {
      letter-spacing: 3.5px;
      opacity: 0.8;
      margin: 20px 0 20px;
      font-family: $avenir-black;
      font-size: 14px;
    }

    .notVerified {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      span {
        font-family: $avenir-heavy;
        line-height: 1.25;
      }

      button {
        height: 30px;
        background-color: $error !important;
        margin: 0;
      }
    }

    .avatarField {
      display: flex;
      flex-direction: column;
      width: 108px;
      margin-top: -54px;

      input[type='file'] {
        display: none;
      }

      .addPhoto {
        text-align: center;
        margin-top: 5px;
      }

      .avatarErrors {
        color: $error;
        font-size: 12px;
        text-align: center;
        margin-top: 2px;
      }

      .avatar {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.04), 0 1px 22px 0 rgba(0, 0, 0, 0.57);
        border-radius: 50%;

        .addPhoto {
          position: absolute;
          bottom: 5px;
          font-size: 11px;
        }

        button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.7);
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    h6 {
      font-size: 16px;
      line-height: 1.25;
      opacity: 0.9;
      margin: 20px 0 15px;
    }

    .tagSelection {
      .title {
        padding: 0;
        margin-top: 5px;

        h2 {
          font-size: 16px;
          line-height: 1.25;
          opacity: 0.9;
          margin: 20px 0 15px;
        }

        h3 {
          display: none;
        }
      }

      .inputField {
        padding: 0;
      }

      .popularContainer,
      .selectedContainer {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

      .selectedContainer {
        text-align: center;
        border-bottom: 1px solid $border-grey;
      }

      .popularContainer {
        padding-bottom: 25px;
      }
    }

    .select {
      margin-bottom: 25px;
    }
  } // end of form

  .buttons {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    right: 0;
    left: 0;
    padding: 0 35px;
    height: 120px;
    background-color: $white;
    box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);

    @include small-desktop-and-up {
      width: 50%;
      left: auto;
    }

    @media screen and (min-width: 1280px) {
      width: 35%;
    }

    button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.phoneInput {
  position: relative;
  margin-bottom: 20px;
  color: $primary-dark !important;
  outline: none !important;
  --PhoneInput-color--focus: #5b00db;
  --PhoneInputCountryFlag-borderColor--focus: #5b00db;
  --PhoneInputCountrySelectArrow-color--focus: #5b00db;
  --PhoneInputCountrySelectArrow-color: #5b00db;

  input {
    height: 40px;
    border: 1px solid $mid-grey;
    border-radius: 20px;
    padding: 0 14px 0 50px;
    font-size: 16px;
    font-family: $avenir-primary;
    outline: none;

    &:focus-visible {
      outline: none;
      border: 2px solid $main;
    }
  }

  input::placeholder {
    color: rgba(10, 13, 26, 0.4);
  }
}

.phoneInputError {
  input {
    border: 1px solid #f44336;

    &:focus {
      border: 2px solid #f44336;
    }
  }
}

.error {
  position: absolute;
  top: 40px;
  left: 14px;
  color: #f44336;
  font-size: 12px;
}

.noHover {
  &:hover {
    cursor: default !important;
  }
}
