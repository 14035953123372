@import 'styles/variables';

.badgeContainer {
  position: relative;

  .badge {
    position: absolute;
    top: 0;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    max-width: 25px;
    height: auto;
    border-radius: 7.5px;
    font-size: 10px;
    font-family: $avenir-heavy;
    background-color: $main;
    padding: 0 3px;
    color: $white;
    z-index: 1;
  }
}
