.discountCodeDialog {
  .header {
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .input {
    padding: 30px 15px;
  }

  .buttons {
    position: absolute;
    display: flex;
    width: 65%;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);

    button:first-child {
      color: #888;
      letter-spacing: 0.94px;
    }
  }
}
