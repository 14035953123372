@import 'styles/mixins';

.container {
  .card {
    height: 115px;
    margin: 20px 10px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    color: #323643;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .cardInfo {
      .brand {
        font-family: Avenir;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 900;
      }

      .name {
        height: 19px;
        font-family: 'Avenir Roman';
        font-size: 14px;
        font-weight: normal;
      }

      .exp {
        opacity: 0.5;
        font-family: 'Avenir Roman';
        font-size: 14px;
      }
    }
  }

  .addButton {
    width: 120px;
    height: 40px;
    padding: 0 12px;
    border-radius: 25px;
    border: solid 1.6px #c8ccd9;
    margin: 0 auto;
    display: block;
    position: relative;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    color: #323643;
  }

  .bottom {
    height: 120px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);
    background-color: #fff;

    @include small-desktop {
      width: 50%;
      margin-left: auto;
    }

    @include desktop {
      width: 35%;
      margin-left: auto;
    }

    button {
      width: 70%;
    }
  }
}
