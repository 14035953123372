@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 15px 0 20px;
  box-shadow: 0 2px 30px 0 $border-grey;
  background-color: #f7f7fb;
  z-index: 10;

  @include small-desktop-and-up {
    display: none;
  }

  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 500px) {
      width: 80%;
    }

    @include tablet-and-up {
      width: 60%;
    }

    a,
    .profileLink {
      flex: 1;
    }

    .profileLink {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 60px;

      span {
        font-size: 12px;
        font-family: $avenir-heavy;
        color: $primary-dark;
        margin-top: 2px;
      }
    }

    .active {
      .navIcons {
        color: $main;

        svg {
          filter: invert(89%) sepia(90%) saturate(10000%) hue-rotate(269deg) brightness(75%)
            contrast(101%);
        }
      }

      span {
        color: $main;
      }
    }

    .navIcons {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: $avenir-heavy;
      font-size: 12px;
      letter-spacing: -0.22px;
      color: #2f2d3f;

      svg {
        width: 100%;
      }

      span {
        margin-top: 5px;
        text-align: center;
      }

      .addIcon {
        width: 18px;
        height: 18px;
        margin: 6px;
        filter: invert(65%) sepia(90%) saturate(2196%) hue-rotate(204deg) brightness(130%)
          contrast(40%);
      }
    }
  }
}
