@import 'styles/variables';
@import 'styles/mixins';

.galleryContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(50, 54, 67, 0.85);
  padding: 20px;
  backdrop-filter: blur(10px);

  ul {
    overflow: auto;
  }

  .alert {
    margin: 20px;

    @include desktop {
      width: 40%;
    }
  }

  .center {
    justify-content: center;
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $mid-grey;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    opacity: 0.8;
    z-index: -1;
  }

  .bin {
    position: absolute;
    width: 32px !important;
    height: 32px !important;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 2;
    cursor: pointer;

    circle {
      fill: transparent;
    }
  }

  .buttons {
    margin: 10px 0 45px;

    @include small-desktop-and-up {
      margin: 30px 0 20px;
      height: auto !important;
    }

    button {
      background-color: $white;
      min-width: 130px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  input[type='file'] {
    display: none;
  }
}

.closeIcon {
  top: 50px;
  left: 20px;
}
