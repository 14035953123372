@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.container {
  min-height: calc(100vh - 250px) !important;
  padding: 90px 0 40px !important;

  &.paddingTop {
    padding-top: 50px !important;
  }

  h1 {
    margin-bottom: 20px;
    font-size: 24px;

    @include desktop {
      font-size: 50px;
      margin-bottom: 40px;
    }
  }

  h3 {
    font-size: 14px;
    color: $main;
    font-family: $avenir-black;
    letter-spacing: 3.5px;
    margin-bottom: 15px;
    opacity: 0.8;
    text-transform: uppercase;
  }

  button {
    // margin-top: 10px;
  }

  .whoAreYou {
    margin-bottom: 20px;

    h3 {
      text-align: center;
    }

    button {
      margin-top: 0;
    }
  }

  .goToLogin {
    width: 100%;
    color: $main;
    font-family: $avenir-heavy;
    text-align: center;
    margin-top: 20px;

    @include desktop {
      font-size: 18px;
    }

    a {
      color: $main;
    }

    a:hover {
      color: lighten($main, 15%);
    }
  }
}

.guestContainer {
  padding: 5px 0 35px !important;
  border-bottom: 1px solid $border-grey;
  margin-bottom: 30px;
}

.form {
  position: relative;
}

.bottomLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;

  @include desktop {
    font-size: 18px;
  }

  a {
    margin-top: 20px;
    font-family: $avenir-heavy;
    color: $main;

    &:hover {
      color: lighten($main, 15%);
    }
  }
} // end of bottomLinks

.phoneInput {
  position: relative;
  margin-bottom: 20px;
  color: $primary-dark !important;
  outline: none !important;
  --PhoneInput-color--focus: #5b00db;
  --PhoneInputCountryFlag-borderColor--focus: #5b00db;
  --PhoneInputCountrySelectArrow-color--focus: #5b00db;
  --PhoneInputCountrySelectArrow-color: #5b00db;

  input {
    height: 40px;
    border: 1px solid $mid-grey;
    border-radius: 20px;
    padding: 0 14px 0 50px;
    font-size: 16px;
    font-family: $avenir-primary;

    &:focus-visible {
      outline: none;
      border: 2px solid $main;
    }
  }

  input::placeholder {
    color: rgba(10, 13, 26, 0.4);
  }
}

.phoneInputError {
  input {
    border: 1px solid #f44336;

    &:focus {
      border: 2px solid #f44336;
    }
  }
}

.inputError {
  fieldset {
    border-color: #f44336 !important;

    &:focus {
      border: 2px solid #f44336;
    }
  }
}

.error {
  position: absolute;
  top: 40px;
  left: 14px;
  color: #f44336;
  font-size: 12px;
}
