@import 'styles/variables';
@import 'styles/mixins';

.container {
  position: relative;
  min-height: 100%;
  padding: 90px 25px 160px !important;
  text-align: center;
  margin: 0 auto;
  text-align: left;
  overflow-x: hidden;

  @media screen and (min-width: 500px) {
    width: 70%;
  }

  @include small-desktop {
    width: 50%;
  }

  @include desktop {
    width: 40%;
  }

  &.noPadding {
    padding: 0 !important;
  }

  &.noHorizontalPadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  label {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.25;
    opacity: 0.9;
    margin-top: 30px;
  }

  .error {
    border-color: $error;
  }

  .goBack {
    margin: -15px 0 0 -15px;

    span {
      width: 20px;
      height: 20px;
    }

    svg {
      transform: rotate(90deg);
    }
  }
}

.tagSelection {
  .popularContainer {
    padding-bottom: 0;
  }
}

.step {
  width: 50px !important;
}

.lastStep {
  width: 100%;
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin: 0;
  }

  button {
    padding-right: 0;

    &:hover {
      background-color: transparent;
    }
  }
}

.additionalInfo {
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
  opacity: 0.9;
  font-family: $avenir-medium;
  color: $primary-dark;

  div:first-child {
    margin-bottom: 10px;
  }
}

.infoBox {
  color: #00adef;
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(0, 173, 239, 0.1);
  border-radius: 10px;
}
