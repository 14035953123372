@import 'styles/variables.scss';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  width: 100%;
  background-color: $primary-dark;
  z-index: 1000;
  padding: 38px 10px 18px;

  .links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 500px;
    line-height: 1.56;

    a {
      color: $calcite;
      font-size: 18px;
      padding-right: 20px;

      &:hover {
        color: $periwinkle;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .socialNetworks {
    display: flex;
    margin-top: auto;

    a {
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .facebook:hover {
      path {
        fill: $facebook;
      }
    }

    .twitter {
      background-color: #c8ccd9;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $twitter;
      }
    }

    .instagram:hover {
      path {
        fill: url(#ig-gradient);
      }
    }
  }

  .copyright {
    color: $mid-grey;
    margin-top: auto;
    text-align: center;
  }
}
