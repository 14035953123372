@import '../variables.scss';

.container {
  padding: 20px 15px 135px;
  max-width: 500px;
  margin: 0 auto;

  h2 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .box {
    position: relative;
    width: 90%;
    margin: 0 auto 20px;
    background-color: rgba(18, 223, 199, 0.2);
    border-radius: 10px;
    padding: 20px 40px;
    color: $primary-dark;

    .iconContainer {
      position: absolute;
      top: 10px;
      left: -20px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $main-green;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  strong {
    color: $main;
  }

  .QRCode {
    margin: 20px auto;
    text-align: center;
  }

  .copyCode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    button {
      height: 30px;
    }
  }

  pre {
    color: $main;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 16px;
  }

  .codes {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    background-color: $bg-grey;
    border-radius: 10px;

    pre {
      width: 50%;
      text-align: center;
      font-size: 16px;
    }
  }
}
