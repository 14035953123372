@import 'styles/variables';

.container {
  padding: 25px 20px;

  h3 {
    font-size: 16px;
    opacity: 0.9;
    line-height: 1.25;
    text-align: center;
  }

  h4 {
    font-size: 16px;
    font-family: $avenir-medium;
    line-height: 20px;
    text-align: center;
    font-weight: 500;
    margin: 10px 0 30px;
  }

  textarea {
    margin: 15px 0 25px;
  }

  .buttons {
    display: flex;

    button:first-child {
      margin-right: 10px;
    }
  }

  .priceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    border-bottom: 1px solid $mid-grey;
    padding-bottom: 5px;
    font-size: 16px;
    margin-top: -20px;
  }
}

.priceChoices {
  display: flex;
  justify-content: center;
  margin: 20px 0 40px;
  gap: 10px;
}
