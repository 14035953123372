@import 'styles/variables';
@import 'styles/mixins';

.profileVerification {
  overflow: auto;
  padding-bottom: 144px !important;

  .content {
    text-align: center;
    padding: 40px 0 0;

    h3 {
      color: $main;
      letter-spacing: 3.5px;
      font-size: 14px;
      opacity: 0.8;
      font-family: $avenir-black;
    }

    h2 {
      font-size: 24px;
      padding: 20px 0;
    }

    .description {
      margin-bottom: 20px;

      span {
        font-family: $avenir-heavy;
      }
    }

    .icon {
      svg {
        filter: invert(35%) sepia(19%) saturate(42%) hue-rotate(186deg) brightness(102%)
          contrast(92%);
      }

      svg:hover {
        cursor: pointer;
        opacity: 0.85;
      }
    }

    .network {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .analyticsDropzones {
        width: 100%;
      }

      .dropzoneContainer {
        width: 100%;
        margin-left: 15px;
      }
    }

    .networkContainer {
      padding-bottom: 15px;
      padding-top: 20px;

      .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 20px 10px;
        background-color: $bg-grey;
        border-radius: 10px;
        margin-bottom: 25px;
        margin-top: -10px;

        .label {
          display: flex;
          align-items: center;
          margin-right: 15px;
          margin-bottom: 5px;
        }
      }
    }

    .urlContainer {
      padding-bottom: 30px;
      padding-top: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.15);

      .urlBox {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 160px;
        padding: 15px;
        border-radius: 10px;
        border: 1px solid $mid-grey;

        &.pending {
          background-color: $light-grey;
          border: none;
        }

        &.verified {
          background-color: #e8fefc;
          border: none;
        }

        &.rejected {
          border: 2px solid $error;
        }

        .chip {
          margin-left: auto;
        }

        .verifiedIcon {
          position: absolute;
          top: -12px;
          right: -15px;
        }

        h5 {
          font-family: $avenir-heavy;
          font-size: 15px;
        }
      }
    }

    .networkName {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .name {
        font-family: $avenir-heavy;
        font-size: 18px;
        letter-spacing: -0.1px;
        display: flex;
        align-items: center;
      }

      a {
        text-decoration: underline;
        color: $primary-dark;
        text-underline-offset: 2px;
      }

      img {
        width: 30px;
      }
    }

    .goBack {
      display: flex;
      align-items: center;
      color: $main;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      svg {
        transform: rotate(180deg);
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }

  .stepper {
    justify-content: center !important;

    div:first-child {
      margin-right: 10px;
    }
  }

  .influencerNetwork {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .name {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .chip {
        height: 25px;
      }
    }
  }

  .closeIcon {
    position: absolute;
    padding: 15px;
    left: 0px;
    top: 5px;
  }

  .bottomLink {
    color: $main;
    margin: 5px 0 20px;
    text-decoration: underline;
    text-underline-offset: 3px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.fullWrapper {
  left: 0;
}
