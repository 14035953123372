@import 'styles/variables';
@import 'styles/mixins';

.dialog {
  .appBar {
    position: relative;
  }

  .networkInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin: 20px 0;

    @include tablet-and-up {
      width: 70%;
    }

    h3 {
      width: 40%;
      height: 40px;
      line-height: 40px;
      align-self: flex-end;
      justify-self: center;
    }

    div {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $avenir-heavy;
      font-size: 20px;
      background-color: $light-grey;
      border-radius: 28px;
      height: 40px;

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }

  .buttonContainer {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 20px 0;
    display: flex;
    margin-top: auto;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);
    background-color: #fff;

    div {
      display: block;
      width: 85%;
    }
  }
}
