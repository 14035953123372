@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.inventorySpaces {
  width: 100%;
  background-color: $bg-grey;
  z-index: 1;
  padding: 15px;
  grid-gap: 10px;
  display: grid;
  grid-template-columns: repeat(4, minmax(60px, 150px));

  &.twoColumns {
    grid-template-columns: repeat(2, minmax(60px, 300px));
  }

  &.threeColumns {
    grid-template-columns: repeat(3, minmax(60px, 200px));
  }

  .space {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 5px;
    background-color: $white;
    border-radius: 9px;
    overflow-wrap: break-word;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    &.selectedSpace {
      background-color: $bg-grey;
    }

    span {
      font-family: $avenir-heavy;
      font-size: 12px;
      line-height: 1.25;
      opacity: 0.9;
      margin-top: 20px;

      @media screen and (min-width: '390px') {
        font-size: 14px;
      }
    }

    .checkIcon {
      position: absolute;
      right: -12px;
      top: -6px;

      @include tablet-and-up {
        right: 0;
      }
    }
  }
}
