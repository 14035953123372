@import 'styles/variables';
@import 'styles/mixins';

.profilesContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;
  padding-right: 15px;

  .profiles {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 10px 0 30px;

    .profile {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 5px 10px;
    }

    .profileImg:hover {
      cursor: pointer;
    }
  }

  .button {
    height: 30px;
    padding: 0 8px 0 12px;
    background-color: $light-grey;

    &:hover {
      background-color: #d5d5d5;
    }

    span {
      text-transform: initial;
    }

    svg {
      filter: invert(7%) sepia(73%) saturate(5431%) hue-rotate(265deg) brightness(65%)
        contrast(133%);
    }
  }

  .iconContainer {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-grey;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
      transition: all 0.2s ease-in-out;
    }
  }
} // end of profilesContainer

.menuContainer {
  padding-top: 2h40px;

  .profile {
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .supportBtn {
    height: 40px;
    padding: 6px 15px;
    background-color: $light-grey;
    color: $primary-dark !important;
    margin: 0 0 20px 20px;
  }

  .profileInfo {
    display: flex;
    align-items: center;
    padding: 0 20px 25px;
    margin-top: 80px;

    h3 {
      font-size: 24px;
      margin-left: 10px;
    }

    h4 {
      font-size: 16px;
    }

    .img {
      width: 50px;
      height: 50px;

      &:hover {
        cursor: pointer;
      }
    }

    .interestSwitch {
      margin-left: 10px;

      &:hover {
        cursor: pointer;
      }

      &.opened {
        .expandIcon {
          transform: rotate(-90deg);
        }
      }

      .expandIcon {
        transform: rotate(90deg);
        width: 18px;
        height: 18px;
      }
    }
  }

  .menuList {
    padding-left: 50px;

    &.bold {
      li {
        font-family: $avenir-heavy;
      }
    }
  }

  .refItem {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 0 16px;
  }

  .bottomSection {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-bottom: 40px;
    color: $primary-dark;

    .straightLine {
      margin: 0 10px;
      width: 2px;
      height: 15px;
      background-color: $light-grey;
    }
  }

  .heading {
    display: flex;
    align-items: center;

    h3 {
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      margin-right: 10px;
      min-width: 24px;
      width: 24px;
      height: 24px;
    }
  }
}

.logout {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  font-family: $avenir-heavy;
  font-size: 16px;

  &:hover {
    color: $main;
    cursor: pointer;
  }

  svg {
    margin-right: 10px;
  }
}

.closeIcon {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.menuUl {
  border-radius: 4px;
  margin-top: 10px !important;
  border: 1px solid $mid-grey;
  background: $white;
  padding: 0 !important;
  z-index: 2;

  &:hover {
    cursor: pointer;
  }

  li {
    font-size: 14px;
    padding: 10px;
    list-style: none;

    &:hover {
      color: $main;

      &:first-child {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }

      &:last-child {
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
      }
    }
  }
}

.popper {
  z-index: 2;
}

.form {
  padding: 40px 20px;

  @include tablet {
    width: 70%;
    margin: 0 auto;
  }
}

.bottomIcon {
  stroke-width: 1.2;

  &:hover path {
    transition: all 0.2s ease-in-out;
    stroke: $main;
  }
}
