
@import 'styles/variables';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f5f0ff;
  text-align: center;
  padding: 25px 20px;

  h4 {
    color: $main;
    letter-spacing: 3.5px;
    opacity: 0.8;
    font-family: $avenir-black;
  }

  p{
    max-width: 300px;
    font-family: $avenir-heavy;
    font-size: 16px;
    margin: 20px auto 30px
  }

  .qrCode {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 318px;
    height: 318px;
    background: $white;
    border-radius: 15px;
    margin: 0 auto 25px;
  }

  button {
    width: 130px;
    position: sticky;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%);
  }
}
