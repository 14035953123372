@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.networks {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 160px;

  .network {
    width: 25%;
    margin-bottom: 10px;
    padding: 10px 0;

    &:nth-child(9),
    &:nth-child(10) {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &.selectedNetwork {
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      background: $bg-grey;
    }

    &.allBorder {
      border-radius: 15px;
    }
  }

  .hoverImg:hover {
    cursor: pointer;
  }
}

.wrapper {
  @include small-desktop-and-up {
    left: 0;
  }
}
