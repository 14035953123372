@import 'styles/mixins';

.dropdownMenuContainer {
  position: absolute;
  right: 0;

  @include small-desktop-and-up {
    position: relative;
  }

  &.avatar {
    right: 150px;
    left: unset;

    @include small-desktop-and-up {
      right: 0;
    }
  }

  button {
    padding: 0;
  }

  .closeIcon {
    z-index: 9999;
  }

  .dropdownMenu {
    z-index: 3;
  }
}
