@import 'styles/variables';
@import 'styles/mixins';

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  background-color: $primary-dark;
  z-index: 12;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 1140px) {
    padding: 0 20px;
  }

  &.noBorder {
    border-bottom: none;
  }

  svg:hover {
    cursor: pointer;
  }

  &.purpleHeader {
    background-color: $main !important;
    border-bottom: none;

    @include small-desktop-and-up {
      background-color: $main !important;
      height: 100px;
    }

    @include desktop {
      padding-left: calc((100% - 1140px) / 2);
      padding-right: calc((100% - 1140px) / 2);
    }
  }

  .logoImg {
    height: 24px;

    @include small-desktop-and-up {
      height: 35px;
    }

    svg {
      width: 130px;
      height: auto;

      &:hover {
        cursor: pointer;
      }

      @include small-desktop-and-up {
        width: 180px;
      }
    }
  }

  .loggedInPosition {
    display: flex;
    width: 100%;
    justify-content: center;

    @include small-desktop-and-up {
      justify-content: flex-start;
      width: fit-content;
    }
  }

  .switchButton {
    display: flex;
    height: 35px;
    width: fit-content;
    border-radius: 20px;
    justify-content: center;
    background-color: rgba(18, 223, 199, 0.1);
    margin: 0 auto;
    padding: 0 10px 0 6px;

    &:hover {
      cursor: pointer;
    }

    .button {
      display: flex;
      align-items: center;

      h3 {
        font-size: 16px;
        color: $light-grey;
        letter-spacing: -0.09px;
        margin: 0 5px 0 10px;
      }
    }
  }

  img:hover {
    cursor: pointer;
  }

  .headerContent {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;

    .whiteWallet {
      position: absolute;
      right: 15px;
      display: flex;
      margin: 0;
      opacity: 1;

      svg {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(350deg) brightness(184%)
          contrast(101%) !important;
      }

      span {
        color: $white;
        margin-right: 5px;
        font-weight: bold;
      }
    }

    .activeWallet {
      svg {
        filter: invert(14%) sepia(98%) saturate(5960%) hue-rotate(267deg) brightness(79%)
          contrast(125%) !important;
      }
    }

    .darkWallet {
      display: flex;
      padding-right: 40px;
      margin: 0;
      opacity: 1;

      svg {
        filter: invert(25%) sepia(160%) saturate(100%) hue-rotate(188deg) brightness(70%)
          contrast(90%);
      }
    }
  }
}

.links {
  display: flex;
  align-items: center;
  margin-left: 75px;
  font-family: $avenir-heavy;
  font-size: 16px;

  a {
    color: $white;
    opacity: 0.8;
    letter-spacing: -0.09px;

    &:visited {
      color: $white;
    }

    &:first-child {
      margin-right: 22px;
    }
  }

  .active {
    opacity: 1;
  }
}

.profileLinks {
  display: flex;
  align-items: center;
  font-family: $avenir-heavy;

  a {
    display: flex;
    font-size: 16px;
    color: #2f2d3f;
    color: $white;
    letter-spacing: -0.09px;
    margin-right: 20px;

    &:hover {
      color: $main;
      color: $main-green;
    }

    &.active {
      color: $main;
      color: $main-green;
      opacity: 1;
    }
  }
}

.buttons {
  display: flex;
  margin-left: auto;

  a,
  button {
    width: 80px;
    height: 31px;
    padding: 0;
    color: $calcite;
    opacity: 0.8;

    &:first-child {
      margin-right: 10px;
    }

    &.active {
      opacity: 1;
    }
  }

  .greenBtn {
    background-color: $main-green;
    color: $primary-dark;
    opacity: 1;

    &:hover {
      background-color: rgba(18, 223, 199, 0.8);
    }
  }
}

.cartIcon {
  position: absolute;
  right: 25px;
  left: unset;
  padding: 15px 25px 15px 0;

  &:hover {
    cursor: pointer;
  }

  @include small-desktop-and-up {
    position: relative;
    right: 0;
  }
}

.cartIconInfluencer {
  @media screen and (max-width: 959px) {
    right: 25px;
    left: unset;
  }
}

.paperStyle {
  padding: 10px;

  .switchOption {
    &:first-child {
      margin-bottom: 5px;
    }

    &:hover {
      background-color: #e8fefc !important;
      border-radius: 5px;
      color: $primary-dark !important;
    }
  }
}
