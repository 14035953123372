@import 'styles/variables';

.container {
  .header {
    padding: 15px;
    border-bottom: 1px solid $border-grey;
    text-align: center;

    h3 {
      font-size: 18px;
    }
  }

  .info {
    padding: 20px 15px 8px;
    background-color: $bg-grey;

    p {
      text-align: center;
    }

    .inputContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      label {
        font-size: 16px;
      }

      button {
        &:first-child {
          padding-right: 5px;
        }

        &:last-child {
          padding-left: 5px;
        }
      }
    }
  }

  .input {
    min-width: 74px;
    width: 80px;
    max-width: 90px;
    margin-bottom: 0;

    & > div:first-child {
      background-color: $white;
      color: $primary-dark;
    }

    input {
      text-align: center;
      font-family: $avenir-heavy;
      letter-spacing: -0.09px;
      padding: 0 5px;
    }
  }

  .inventoryItems {
    padding-bottom: 20px;

    .priceOption {
      position: relative;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-grey;
      padding: 0 15px;

      &:hover {
        cursor: pointer;
        background-color: $subtle-bg;
      }

      .availability {
        display: flex;
        flex: 1;
        align-items: center;
      }

      h4 {
        font-size: 16px;
        margin: 0 10px;
        letter-spacing: -0.1px;
      }
    }

    .numbers {
      .percentage {
        font-family: $avenir-heavy;
        margin: 1px 10px 0 10px;
      }
    }
  }

  .buttons {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
