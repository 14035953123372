@import 'styles/variables';
@import 'styles/mixins';

.buttonsContainer {
  position: relative;
  width: 100%;
  height: 120px;
  padding: 35px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);
  background-color: $white;
  margin-top: auto;
  z-index: 1;

  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    @include small-desktop {
      width: 50%;
      left: auto;
    }

    @include desktop {
      width: 35%;
      left: auto;
    }
  }

  &.sticky {
    position: sticky;
    bottom: 0;
  }

  &.oneButton {
    button {
      margin: 0;
      width: 350px;
    }
  }

  .loader {
    div:first-child {
      width: 30px !important;
    }
  }

  button {
    min-width: 150px;

    &:first-child {
      margin-right: 10px;
    }
  }

  .centerbuttons {
    margin: 0 auto;
    left: 0 !important;
  }
}
