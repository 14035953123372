@import './variables';

h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
button,
input,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

a {
  margin: 0;
  text-decoration: none;
  outline: none;
}
