@import 'styles/variables';
@import 'styles/mixins';

.orderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include small-desktop-and-up {
    height: 100%;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $border-grey;
    padding: 25px 10px;

    .space {
      font-size: 16px;
      font-family: $avenir-book;
    }
  }

  .cover {
    position: relative;
    width: 100%;
    height: 290px;
    padding: 20px;
    background-color: $mid-grey;

    img {
      object-fit: cover;
    }

    .actions {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .content {
    // padding: 20px 15px 0;
    margin-top: 20px;

    .title {
      display: flex;
      justify-content: space-between;
    }

    .slider {
      padding-bottom: 15px;
      border-bottom: 1px solid $border-grey;
      margin-right: 15px;
    }

    .selectContainer {
      padding-top: 20px;
    }

    .networkImgContainer {
      display: flex;
      align-items: center;
      width: fit-content;

      img {
        height: 50px;
        min-width: 50px;
        width: 50px;
      }
    }

    .titleDetails {
      font-size: 26px;
      letter-spacing: -0.14px;
      margin: 10px 0 20px;
    }

    .info {
      font-family: $avenir-medium;
      letter-spacing: -0.09px;
      font-size: 16px;

      div {
        display: flex;
        justify-content: space-between;
      }
    }

    .description {
      font-family: $avenir-book;
      font-size: 16px;
      letter-spacing: -0.09px;
      margin: 25px 0 0;
    }

    .signatureDescription {
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      font-size: 13px;
      font-family: $avenir-medium-oblique;
      line-height: 1.54;
      color: #9f9ead;
    }
  }

  h5 {
    font-size: 16px;
    letter-spacing: -0.09px;
    margin-bottom: 10px;
  }

  .footer {
    position: fixed;
    width: 100%;
    height: 120px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    background-color: $white;

    &.footerDetails {
      box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);
    }

    @include small-desktop-and-up {
      position: relative;
    }

    .price {
      min-width: 100px;
      font-size: 26px;
      font-family: $avenir-black;
      letter-spacing: 0.6px;
      margin-right: 30px;
      text-align: center;
    }

    button {
      min-width: 130px;
    }
  }
}

.closeIcon {
  right: 0;
}
