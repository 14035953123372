@import 'styles/mixins';
@import 'styles/variables';

.congratulations {
  min-height: calc(100vh - 50px) !important;
  padding: 0 20px !important;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f5f0ff;
  place-content: center;
  overflow: auto;

  &.percentageHeight {
    min-height: 100% !important;
  }

  &.fullHeight {
    min-height: 100vh !important;
  }

  @media screen and (min-width: 380px) {
    justify-content: center;
  }

  @include small-desktop-and-up {
    padding: 40px !important;
  }

  h2 {
    font-size: 24px;
    margin: 30px 0 20px;

    @include desktop {
      font-size: 32px;
    }
  }

  h3 {
    margin-top: 25px;
    color: $main;
  }

  p {
    font-size: 18px;
    margin: 15px 0 20px;
    font-family: $avenir-book;
  }

  .buttons {
    a:first-child {
      margin-bottom: 10px;

      button {
        margin: 0 auto;

        @include tablet-and-up {
          width: 60%;
        }

        @include desktop {
          width: 30%;
        }

        &:nth-child(2) {
          margin-top: 15px;
        }
      }
    }
  }

  .gigSetupBtn {
    margin: 0 auto;

    @media screen and (min-width: 600px) {
      width: 50%;
    }

    @include desktop {
      width: 33%;
    }

    @include large-desktop {
      width: 25%;
    }
  }
}
