@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.dialog {
  .appBar {
    position: relative;
  }

  .title {
    font-size: 18px;
    letter-spacing: -0.1px;
    text-align: center;
    padding: 0 5px;
  }

  .modalContent {
    .label {
      font-size: 16px;
      margin-bottom: 24px;
      line-height: 1.25;
    }

    .content {
      font-size: 14px;
    }

    .contentDescription {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin-right: 20px;
      }

      svg {
        min-width: 20px;

        &:hover {
          cursor: pointer;
          filter: brightness(105%);
        }
      }

      textarea {
        font-size: 16px;
        background-color: transparent;
        border-radius: 15px;
        border: 1px solid $mid-grey;
      }
    }

    .addPrice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      padding: 0 15px;

      h4 {
        font-size: 16px;
        line-height: 1.25;
      }

      h5 {
        color: $main;
        font-size: 14px;
      }
    }

    .inventoryItems {
      .priceOption {
        position: relative;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $border-grey;
        padding: 0 15px;

        &:hover {
          cursor: pointer;
          background-color: $subtle-bg;
        }

        .availability {
          display: flex;
          flex: 1;
          align-items: center;
        }

        h4 {
          font-size: 18px;
          margin: 0 10px;
          letter-spacing: -0.1px;
        }

        .remove {
          font-family: $avenir-medium;
          padding-left: 10px;

          svg {
            filter: invert(41%) sepia(40%) saturate(1348%) hue-rotate(317deg) brightness(104%)
              contrast(123%);
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .description {
      opacity: 0.5;
      padding: 0 15px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .buttons {
    position: sticky;
    bottom: 0;
    width: 100%;
    margin: auto auto 0;
    padding: 25px 0;
    text-align: center;
    box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);
    background-color: $white;

    @include small-desktop-and-up {
      width: 600px;
    }

    button {
      width: 135px;

      &:first-child {
        margin-right: 10px;
        color: #888;
      }
    }
  }
}
