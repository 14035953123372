@import 'styles/variables';

.container {
  .header {
    display: flex;
    justify-content: center;
    padding: 15px;
    font-family: $avenir-heavy;
    font-size: 18px;
    border-bottom: 1px solid $border-grey;
  }

  ul {
    padding: 13px 10px;

    li {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 10px 5px;
      margin: 5px 0;
      font-size: 16px;
      font-family: $avenir-heavy;
      line-height: 20px;
      border-radius: 5px;

      &:hover {
        background-color: #e8fefc;
        cursor: pointer;
      }

      svg {
        margin-right: 15px;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: column;

    small {
      display: flex;
      opacity: 0.6;
      margin-top: -2px;
      font-size: 12px;
      font-family: $avenir-primary;
    }
  }
}
