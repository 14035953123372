@font-face {
  font-family: 'Avenir Book';
  src: url('../public/fonts/Avenir\ Book.ttf');
}

@font-face {
  font-family: 'Avenir Black';
  src: url('../public/fonts/Avenir\ Black.ttf');
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url('../public/fonts/Avenir\ Heavy.ttf');
}

@font-face {
  font-family: 'Avenir Medium';
  src: url('../public/fonts/Avenir\ Medium.ttf');
}

@font-face {
  font-family: 'Avenir Roman';
  src: url('../public/fonts/Avenir-Roman.ttf');
}

@font-face {
  font-family: 'Avenir Oblique';
  src: url('../public/fonts/Avenir-Oblique.ttf');
}

@font-face {
  font-family: 'Avenir MediumOblique';
  src: url('../public/fonts/Avenir-MediumOblique.ttf');
}

@font-face {
  font-family: 'HelveticaNeue Medium';
  src: url('../public/fonts/HelveticaNeue\ Medium.ttf');
}

@font-face {
  font-family: 'HelveticaNeue Regular';
  src: url('../public/fonts/HelveticaNeue\ Regular.ttf');
}
