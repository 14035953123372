@import 'styles/variables.scss';

.tagSelection {
  .title {
    padding: 40px 25px 0;

    h2 {
      margin: 15px 0 30px;
    }
  }

  h4 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .inputField {
    padding: 0 25px;
  }

  .selectedContainer {
    border-bottom: 1px solid $border-grey;
    padding: 5px 25px 10px;

    span {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }

    @media screen and (min-width: 500px) {
      border-bottom: 1px solid transparent;
    }

    .selectedTags {
      text-align: left;
    }

    .subTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
    }
  }

  .popularContainer {
    text-align: left;
    padding: 20px 25px 160px;

    .popularTags {
      overflow: scroll;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .noPadding {
    padding-bottom: 0;
  }

  .chip {
    margin: 0 10px 10px 0;
    width: fit-content;

    svg {
      width: 10px;
      height: 10px;
      margin: 0 10px 0 0;
    }

    span {
      margin-bottom: 2px;
    }
  }

  .select {
    text-align: left;
    padding: 15px 25px 160px;
  }
}
